import * as _ from 'lodash'

export default {
  metaInfo () {
    return {
      title: (_.hasIn(this.item, 'meta_fields')) ? this.item.meta_fields.title || this.$route.meta.title || this.title : [],
      meta: (_.hasIn(this.item, 'meta_fields')) ? [{ vmid: 'description', name: 'description', content: this.item.meta_fields.description }] : []
    }
  }
}
