<template>
  <div class="flex items-center border-t py-4" @click="$emit('click')">
    <div class="mr-4">
      <span class="px-2 py-1 bg-primary text-white text-h5 font-bold inline-block leading-tight">{{ item.type }}</span>
    </div>
    <router-link
      class="font-bold w-full"
      :to="localizedRoute(item.url)"
    >
      {{ item.title }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'SearchResult',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
