<template>
  <div class="container mx-auto lg:px-17">
    <div class="flex">
      <div class="w-full">
        <breadcrumbs :routes="[{name: 'Home', route_link: '/'}]" :active-route="this.$t('CATALOG SEARCH')" />
      </div>
    </div>
    <div class="pb-8 md:pb-16 lg:pb-24">
      <h1 class="text-black text-lg leading-h3 font-black tracking-lg uppercase text-center">{{ $t('Catalog Search') }}</h1>
      <form @submit.prevent="makeSearch" class="flex flex-wrap md:flex-no-wrap mt-lg mb-8 md:mb-16 lg:mb-24">
        <input
          ref="search"
          id="search"
          v-model="search"
          @input="makeSearch"
          class="block w-full bg-white text-dark font-sans text-average leading-sm tracking-xs px-5 py-sm border border-dark mb-sm md:mb-0 md:mr-sm"
          :placeholder="$t('I am looking for...')"
          :aria-label="$t('I am looking for...')"
          type="search"
          autofocus="true"
          autocomplete="off"
        >
        <button-full type="submit" :title="$t('Search')" class="flex-none w-full md:w-auto">
          Search
          <i class="ss-gizmo ss-right"></i>
        </button-full>
      </form>
      <div v-if="resultsCount">
        <h3 class="mb-4 font-normal text-base">
          {{ $t('Results') }}: ({{ resultsCount }})
        </h3>
        <template v-if="products.length">
          <search-result
            v-for="item in products"
            :key="item.id"
            :item="productResult(item)"
          />
        </template>
        <template v-if="categories.length">
          <search-result
            v-for="item in categories"
            :key="item.category_id"
            :item="categoryResult(item)"
          />
        </template>
        <template v-if="pages.length">
          <search-result
            v-for="item in pages"
            :key="item.id"
            :item="pageResult(item)"
          />
        </template>
      </div>
      <div v-if="emptyResults" class="text-h3">
        {{ $t('No results') }}
      </div>
      <div v-if="!emptyResults && !resultsCount" class="text-h3">
        {{ $t('Start typing to see results') }}
      </div>
    </div>
  </div>
</template>

<script>
import VueOfflineMixin from 'vue-offline/mixin'
import i18n from '@vue-storefront/i18n'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import { Logger } from '@vue-storefront/core/lib/logger'
import { Search } from '@vue-storefront/core/modules/catalog/components/Search'
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore'
import { prepareCategorySearchQuery, preparePageSearchQuery } from 'theme/queries/searchPanel'
import Breadcrumbs from 'theme/components/core/Breadcrumbs'
import SearchResult from 'theme/components/core/blocks/SearchPanel/SearchResult'
import ButtonFull from 'theme/components/theme/ButtonFull'

export default {
  name: 'Search',
  mixins: [
    VueOfflineMixin,
    Search
  ],
  components: {
    Breadcrumbs,
    SearchResult,
    ButtonFull
  },
  data () {
    return {
      categories: [],
      pages: [],
      size: 20
    }
  },
  computed: {
    resultsCount () {
      return this.products.length + this.categories.length + this.pages.length
    },
    config () {
      return this.$store.state.config
    }
  },
  mounted () {
    this.search = ''
  },
  methods: {
    async makeSearch () {
      if (this.search !== '' && this.search !== undefined) {
        let productQuery = this.buildSearchQuery(this.search)

        this.emptyResults = true

        try {
          let prodResp = await this.$store.dispatch('product/list', { query: productQuery, start: 0, size: this.size / 2, updateState: false })

          this.products = prodResp.items
          this.emptyResults = this.emptyResults && prodResp.items.length < 1
        } catch (err) {
          Logger.error(err, 'components-search')()
        }

        let categoryQuery = prepareCategorySearchQuery(this.search)

        try {
          let catResp = await quickSearchByQuery({ entityType: 'category', query: categoryQuery, sort: 'position:asc', start: 0, size: this.size / 2, includeFields: this.config.entities.optimize ? this.config.entities.category.includeFields : null, excludeFields: this.config.entities.optimize ? this.config.entities.category.excludeFields : null })

          this.categories = catResp.items
          this.emptyResults = this.emptyResults && catResp.items.length < 1
        } catch (err) {
          Logger.error(err, 'components-search')()
        }

        let pageQuery = preparePageSearchQuery(this.search)

        try {
          let pageResp = await quickSearchByQuery({ entityType: 'story', query: pageQuery, index: this.config.storyblok.indexName, sort: 'first_published_at:asc', start: 0, size: this.size / 2 })

          this.pages = pageResp.items.filter(page => page.content.meta_fields)

          this.emptyResults = this.emptyResults && pageResp.items.length < 1
        } catch (err) {
          Logger.error(err, 'components-search')()
        }
      } else {
        this.products = []
        this.categories = []
        this.pages = []
        this.emptyResults = 0
      }
    },
    productLink (product) {
      const routeData = {
        name: product.type_id + '-product',
        params: {
          parentSku: product.parentSku ? product.parentSku : product.sku,
          slug: product.slug,
          childSku: product.sku
        }
      }
      return localizedRoute(routeData, currentStoreView().storeCode)
    },
    categoryLink (category) {
      return {
        name: 'category',
        params: {
          slug: category.slug
        }
      }
    },
    productResult (product) {
      return {
        type: i18n.t('Product'),
        title: product.name,
        url: this.productLink(product)
      }
    },
    categoryResult (category) {
      return {
        type: i18n.t('Category'),
        title: category.name,
        url: ['/', category.url_path].join('')
      }
    },
    pageResult (page) {
      return {
        type: i18n.t('Page'),
        title: page.name,
        url: page.full_slug !== 'home' ? page.full_slug : '/'
      }
    }
  }
}
</script>
