<template>
  <div id="home">
    <sb-render v-if="story && story.content" :item="story.content"/>
  </div>
</template>

<script>
// Core pages
import Home from '@vue-storefront/core/pages/Home'
import StoryblokMixin from 'src/modules/vsf-storyblok-module/components/StoryblokMixin'
import Render from 'src/modules/vsf-storyblok-module/components/Render'
import MetaInfo from 'theme/mixins/pages/MetaInfo'

export default {
  mixins: [Home, StoryblokMixin, MetaInfo],
  components: {
    Render
  },
  created () {
    // Load personal and shipping details for Checkout page from IndexedDB
    this.$store.dispatch('checkout/load')
  }
}
</script>
