import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'

export function prepareCategorySearchQuery (queryText) {
  let searchQuery = new SearchQuery()

  searchQuery = searchQuery
    .setSearchText(queryText)
    .applyFilter({ key: 'level', value: { 'in': [1, 2, 3, 4] } })/* 0 = default category */
    .applyFilter({ key: 'is_active', value: { 'eq': true } })
    .applyFilter({ key: 'product_count', value: { 'gt': 0 } })

  return searchQuery
}

export function preparePageSearchQuery (queryText) {
  let searchQuery = new SearchQuery()

  searchQuery = searchQuery
    .setSearchText(queryText)

  return searchQuery
}
