<template>
  <div class="breadcrumbs text-h6 uppercase py-5">
    <span v-for="link in routes" :key="link.route_link">
      <router-link :to="localizedRoute(link.route_link)" class="hover:underline">
        {{ link.name | htmlDecode }}
      </router-link><span class="px-px">/</span>
    </span>
    <span class="text-grey-12">
      {{ activeRoute | htmlDecode }}
    </span>
  </div>
</template>

<script>
import Breadcrumbs from '@vue-storefront/core/compatibility/components/Breadcrumbs'

export default {
  mixins: [Breadcrumbs]
}
</script>
